import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import './Product.scss';
import HeaderWithStock from '../MobileHeaderWithStock/MobileHeaderWithStock';
import ImageGallery from 'react-image-gallery';
import withSelections from 'react-item-select';
import { useNavigate } from "react-router-dom";
// import {
//   Provider as AlertProvider,
//   useAlert,
//   positions,
//   transitions
// } from 'react-alert';
import axios from "axios";
import Footer from '../../mobileComponents/MobileFooter/MobileFooter';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useToast } from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import dolyameAdvantageIconOne from '../../assets/images/dolyame-drawer_advantage-1.svg';
import dolyameAdvantageIconTwo from '../../assets/images/dolyame-drawer_advantage-2.svg';
import LogoDolyame from '../../assets/images/LogoDolyame.svg';
import WidgetDolyameBtn from './Dolyame/WidgetDolyameBtn';
import { NonceProvider } from 'react-select';



//import MerchandiseHeader from './Merchandise/MerchandiseHeader/MerchandiseHeader';
// import MerchandiseList from '../../Merchandise/MerchandiseList/MerchandiseList';
// import Footer from '../../Footer/Footer';
// import Pagination from './Merchandise/Pagination/Pagination';



export default function Product( {cart, setCart, getCartTotal, paymentMethod, setPaymentMethod} ) {
    const {id} = useParams();
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    function divideAndRound(sum) {
        // Проверяем, является ли sum числом
        if (typeof sum !== 'number') {
          throw new Error('Передайте число в качестве аргумента');
        }
      
        // Делим сумму на 4 и округляем результат
        const result = Math.round(sum / 4);
      
        return result;
    }
    
    if(product) {
        var stack = Object.keys(product.attributes.ostatok).length;
        for (var key in product.attributes.ostatok) {

            if(product.attributes.ostatok[key]==0) {
                stack--;
            }

        }
    }

    function datesDolyame() {
       const months = {
            0: 'янв',
            1: 'фев',
            2: 'марта',
            3: 'апр',
            4: 'мая',
            5: 'июня',
            6: 'июля',
            7: 'авг',
            8: 'сен',
            9: 'окт',
            10: 'ноя',
            11: 'дек',
        }
      
        const dateNow = Date.now() / 1000;
	    const dates = [];
        const formatedDates = [];

        for(let count = 0; count < 3; count++) {
            if(count == 0) {
            	dates[count] = dateNow + 1209600;
            } else {
            	dates[count] = dates[count - 1] + 1209600;
            }
        }
        
        dates.map((date, id) => {
 			formatedDates[id] = new Date(date * 1000).getDate() + ' '+months[new Date(date * 1000).getMonth()]
        });
    
        return formatedDates;
    }
const addToCart = (product) => {
            let newCart = [...cart];
            let itemInCart = newCart.find(
                (item) => product.attributes.title === item.attributes.title
            );
            if (itemInCart) {
                itemInCart.count++;
                itemInCart.sum=itemInCart.attributes.price*itemInCart.count;
            } else {
                itemInCart = {
                    ...product,
                    count: 1,
                    sum: product.attributes.price,
                    size: selectedSize ? selectedSize : product.attributes.sizes[0],
                };
                itemInCart.attributes.vendorCode = itemInCart.attributes.vendorCode+'-'+(selectedSize ? selectedSize : product.attributes.sizes[0])

                newCart.push(itemInCart);
            }

            setCart(newCart);

            // alert.show(<div className = "merchandise_alert" style={{ color: 'white', textTransform: 'none', width: '195px' }}>Товар добавлен в корзину</div>,
            // {
            //     timeout: 1000,
            //     type: 'success',
            // })
        };
    const goBasketWithDolyame = (product) => {
        let newCart = [...cart];
        let itemInCart = newCart.find(
            (item) => product.attributes.title === item.attributes.title
        );
        if (!itemInCart) {
            itemInCart = {
                ...product,
                count: 1,
                sum: product.attributes.price,
                size: selectedSize ? selectedSize : product.attributes.sizes[0],
            };

            itemInCart.attributes.vendorCode = itemInCart.attributes.vendorCode+'-'+(selectedSize ? selectedSize : product.attributes.sizes[0])

            newCart.push(itemInCart);

            setCart(newCart);
        }

        

        navigate('/basket', {replace: false, state: {dolyame: true}})
    }
    const SkeletonMerch = ({count}) => {
        return (
          <div className='skeleton_merchandise__list' style={{display: 'flex', padding: '20px 15px 30px', width: '100%', boxSizing: 'border-box'}}>
              <div className='skeleton_merchandise__item' style={{width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '25px'}}>
                <Skeleton height='36px' width='80%' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '12px'}} />
                <Skeleton height='363px' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '12px'}} />
                <Skeleton height='24px' width='30%' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '20px'}} />
                <Skeleton height='16px' width='35%' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '16px'}} />
                <Skeleton height='48px' width='35%' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '26px'}} />
                <Skeleton height='51px' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '25px'}} />
                <Skeleton height='48px' width='35%' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '30px'}} />
                <Skeleton height='14px' width='27%' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '8px'}} />
                <Skeleton height='11px' width='50%' baseColor="#ececec" highlightColor="#dedede" style={{marginBottom: '0px'}} />   
              </div>
          </div>
        );
      }

    useEffect(() => {
        fetch(`https://admin.ramilmusic.com/api/merches/${id}?populate=*`)
            .then(res => res.json())
            .then(data => {
                setProduct(data.data)
                setIsLoading(false)
            });
        
    }, []);

    const [selectedSize, setSelectedSize] = useState(false);
    console.log(selectedSize)
        // const currentValueSize = (IdProduct) => {
        //     IdProduct = Number(IdProduct);
        //     console.log(IdProduct)
        //     let cartData = JSON.parse(localStorage.getItem('cart'));        
        //     for (let cartItem of cartData) {
        //         if(cartItem.id === IdProduct) {
        //             return cartItem.size;
        //         } 
        //     }

        //     //return product.size;
        // }
        // if(selectedSize == 'null') {
        //     setSelectedSize(currentValueSize(id));
        //     console.log(selectedSize);
        // }


    // const alert = useAlert();
    let navigate = useNavigate();

    const SizesSelect = ({ListSizes,IdProduct,setCart,cart}) => {

        const handleOnClick = (size,IdProduct) => {
            IdProduct = Number(IdProduct);
            setSelectedSize(size);
            console.log(selectedSize);
            let newCart = [...JSON.parse(localStorage.getItem('cart'))]; 
            let itemInCart = newCart.find(
              (item) => item.id === IdProduct
            );
        
            if(itemInCart) {
                itemInCart.attributes.size = size;
                setCart(newCart);
            } 
            
        }


        var stack = Object.keys(product.attributes.ostatok).length;
        for (var key in product.attributes.ostatok) {

            if(product.attributes.ostatok[key]==0) {
                stack--;
            }

        }

        if(stack!=0) {

            return (
                <>
                    <div className="productSelectSize__label">Размер RU</div>
                    <div className="SizesSelect">
                        {
                            ListSizes.map((size, idx) => (
                                <div key={idx} className={'SizesSelect__size'+((size == selectedSize) ? ' selected' : '')} onClick={event => setSelectedSize(size)}>
                                    <span>{size}</span>
                                </div>
                                )
                            )
                        }
                    </div>
                </>
            )
        } else {
            return '';
        }
    
    }

    const ButtonBuy = ({product}) => {

        let checkInCart = cart.find(
            (item) => product.id === item.id
        );

        const go_to_basket = () => {
            navigate('/basket', { replace: false });
        }

        const addToCart = (product) => {
            let newCart = [...cart];
            let itemInCart = newCart.find(
                (item) => product.attributes.title === item.attributes.title
            );
            if (itemInCart) {
                itemInCart.count++;
                itemInCart.sum=itemInCart.attributes.price*itemInCart.count;
            } else {
                itemInCart = {
                    ...product,
                    count: 1,
                    sum: product.attributes.price,
                    size: selectedSize ? selectedSize : product.attributes.sizes[0],
                };
                itemInCart.attributes.vendorCode = itemInCart.attributes.vendorCode+'-'+(selectedSize ? selectedSize : product.attributes.sizes[0])

                newCart.push(itemInCart);
            }

            setCart(newCart);

            // alert.show(<div className = "merchandise_alert" style={{ color: 'white', textTransform: 'none', width: '195px' }}>Товар добавлен в корзину</div>,
            // {
            //     timeout: 1000,
            //     type: 'success',
            // })
        };
        
        var stack = Object.keys(product.attributes.ostatok).length;
        for (var key in product.attributes.ostatok) {

            if(product.attributes.ostatok[key]==0) {
                stack--;
            }

        }

        if(stack != 0) {

            if (!checkInCart){
                console.log('Заходж');
                return (
                    <div className="productDescription__addToCart" onClick={() => { addToCart(product); toast({ description: "Товар добавлен в корзину", status: 'success', duration: 4000, isClosable: false, position: 'bottom' })}}>Добавить в корзину</div>
                )
            } else {
                return (
                    <div className="productDescription__addToCart productDescription__addToCart_goToBasket" onClick={() => go_to_basket()}>Перейти в корзину</div>
                )
            }
        } else {
            return (
                    <div className="productDescription__addToCart productDescription__addToCart_notAvailable">Нет в наличии</div>
            )
        }


    }




    function genObjGallery(product) {

        if (product) {

            let GalleryImages = [];

            let i = 0;
            for (let ImgGallery of product.attributes.img.data) {
                GalleryImages[i] = {
                    original: 'https://admin.ramilmusic.com'+ImgGallery.attributes.formats.large.url,
                    thumbnail: 'https://admin.ramilmusic.com'+ImgGallery.attributes.formats.large.url
                }
                i++;
            }

            return GalleryImages;

        } else {
            return [];
        }
    }

    let afaf = genObjGallery(product);
    console.log(genObjGallery(product));

    return (
        <div className="productBody">
            <HeaderWithStock getCartTotal={getCartTotal}/>

            {isLoading && 
                <div className="productBodyCart">
                    <div className="productBodyCart__container">
                        <SkeletonMerch count={6}/>
                    </div>
                </div>    
            } 

            {product && (
                <>
                    <div className="productBodyCart">
                        <div className="productBodyCart__container">

                            <div className="productBodyCart__half productBodyCart__half_title">
                                <h1 className="productBodyCart__title">{product.attributes.title}</h1>
                            </div>

                            <div className="productBodyCart__half productBodyCart__half_gallery">
                                <ImageGallery items={afaf} thumbnailPosition="right" showNav={false} lazyLoad={false} showPlayButton={false} fullscreen={false} showFullscreenButton={false} />
                            </div>

                            <div className="productBodyCart__half productBodyCart__half_description">
                                <div className="productDescription">
                                    <h2 className="productBodyCart__price">{product.attributes.price}₽</h2>
                                    {stack != 0 &&
                                        <div onClick={onOpen}>
                                            <WidgetDolyameBtn price={product.attributes.price}/>
                                        </div>
                                    }
                                    <Drawer placement='bottom' onClose={onClose} isOpen={isOpen}>
                                        <DrawerOverlay />
                                        <DrawerContent>
                                            <DrawerCloseButton />
                                            {/* <DrawerHeader borderBottomWidth='1px'>Basic Drawer</DrawerHeader> */}
                                            <DrawerBody>
                                                <div className='drawer-content'>
                                                    <div className='drawer-content__title'>Оплатите 25%<br></br>от стоимости покупки</div>
                                                    <div className='drawer-content__desc'>Оставшиеся 3 части спишутся<br></br>автоматически с шагом в две недели</div>
                                                    <div className='drawer-content__advantages' style={{marginBottom: '16px'}}>
                                                        <div className="dolyame-drawer_advantage" style={{marginBottom: '12px'}}>
                                                            <div className='dolyame-drawer_advantage__icon'>
                                                            <div style={{display: 'flex'}}><img src={dolyameAdvantageIconOne} /></div>
                                                            </div>

                                                            <div className='dolyame-drawer_advantage__text'>
                                                                <span>Без процентов и комиссий</span>
                                                            </div>
                                                        </div>

                                                        <div className="dolyame-drawer_advantage">
                                                            <div className='dolyame-drawer_advantage__icon'>
                                                                <div style={{display: 'flex'}}><img src={dolyameAdvantageIconTwo} /></div>
                                                            </div>

                                                            <div className='dolyame-drawer_advantage__text'>
                                                                <span>Как обычная оплата картой</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                       
                                                    <div className='drawer-content__paymentDetails'>
                                                        {/* {[...Array(4)].map((_, index) => ( */}
                                                            <div className={`paymentDetails-item paymentDetails-item_active`}>
                                                                <div className='paymentDetails-item__date'>
                                                                    Сегодня
                                                                </div>
                                                                <div className='paymentDetails-item__sum'>
                                                                    {divideAndRound(product.attributes.price)} ₽
                                                                </div>
                                                                <div className='paymentDetails-item__line'>
                                                                    <div className='line'></div>
                                                                </div>
                                                            </div>
                                                        {/* ))} */}
                                                        
                                                        {datesDolyame().map((date, index) => ( 
                                                            <div className={`paymentDetails-item`}>
                                                                <div className='paymentDetails-item__date'>
                                                                    {date}
                                                                </div>
                                                                <div className='paymentDetails-item__sum'>
                                                                    {divideAndRound(product.attributes.price)} ₽
                                                                </div>
                                                                <div className='paymentDetails-item__line'>
                                                                    <div className='line'></div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    
                                                    </div>
                                                    <div className='drawer-content__actions'>
                                                        <div className='btnPayDolyame' style={{marginBottom: '20px'}} onClick={() => goBasketWithDolyame(product)}>
                                                            <div style={{marginRight: '8px'}}>Оплатить</div>
                                                            <img src={LogoDolyame} />
                                                        </div>
                                                        <div className='detailsDolyame' style={{textAlign: 'center'}}>
                                                            <span style={{fontSize: '12px', color: '#00000066'}}>Подробности на </span><a href='https://dolyame.ru' style={{fontSize: '12px', color: '#1771E6', textDecoration: 'none'}}>dolyame.ru</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </DrawerBody>
                                        </DrawerContent>
                                    </Drawer>
                                    <div className="productSelectSize">
                                        <SizesSelect 
                                            ListSizes={product.attributes.sizes} 
                                            IdProduct={id}
                                            setCart={setCart}
                                            cart={cart}
                                        />
                                    </div>
                                    <ButtonBuy product={product}/>
                                    <div className="productDescription__extraInfo">
                                        <div className="productExtraInfo productExtraInfo__deliveryWorldwide">Доставка по всему миру</div>
                                        <div className="productExtraInfo productExtraInfo__refund">14 дней на возврат</div>
                                    </div>
                                    <div className="productDescription__structure">
                                        <div className="productStructure__label">Описание</div>
                                        <div className="productStructure__desc">{product.attributes.structure}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </>
            )}
            <Footer />
        </div>
    );
}