import React from 'react';
import './MobileMusics.scss';
import MusicsHeader from './MobileMusicsHeader/MobileMusicsHeader';
import MusicsList from './MobileMusicsList/MobileMusicsList';
import redX from '../../assets/images/Group 3.png';


export default function MobileMusics() {
    return (
        <div id="music">
            <MusicsHeader />
            <MusicsList />
            <img src={redX} className="redX"/>

        </div>
    );
}