import React, { useEffect, useState } from 'react';
import Main from './components/pages/main/Main';
import Merchandise from './components/pages/merch/MerchMain';
import Payment from './components/Payment/PaymentMain'
import Order from './components/Order/OrderMain'
import EndofPayment from './components/EndofPayment/EndofPaymentmain'
import Basket from './components/Basket/Basketmain'
// import Tour from './components/pages/tour/tour';
// import Tourtest from './components/pages/tourtest/tour';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Refund from './components/Refund/Refund';
import Password from './components/pages/password/password';
import Product from './components/pages/product/ProductMain';
import axios from "axios"

import {
  Routes,
  Route
} from 'react-router-dom';


  const cartFromLocalStorage = (JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [])// "[]"
  const stageCheckoutFromLocalStorage = (localStorage.getItem('stageCheckout') ? localStorage.getItem('stageCheckout') : 'NOT2')

function App() {
  const [cart, setCart] = useState(cartFromLocalStorage);
  const [checkoutData, setCheckoutData] = useState((JSON.parse(localStorage.getItem('checkoutData')) ? JSON.parse(localStorage.getItem('checkoutData')) : []));
  const [stageCheckout, setStageCheckout] = useState(stageCheckoutFromLocalStorage);
  const [paymentMethod, setPaymentMethod] = useState(localStorage.getItem('paymentMethod') ? localStorage.getItem('paymentMethod') : 'card');

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    console.log('cc');
  }, [cart]);

  useEffect(() => {
    localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
  }, [checkoutData]);

  useEffect(() => {
    localStorage.setItem("stageCheckout", stageCheckout);
  }, [stageCheckout]);

  useEffect(() => {
    localStorage.setItem("paymentMethod", paymentMethod);
    console.log('pm');
  }, [paymentMethod]);

  const [merchItems, setMerchItems] = useState();

  useEffect(() => {
    axios.get('https://admin.ramilmusic.com/api/merches?populate=*')
    .then(function (response) {
      setMerchItems(response.data.data);
      console.log('merchItems');

    })
    .catch(function (error) {
      console.log('error get merch');
    })
  }, [setMerchItems]);




console.log('rerender');
  const getCartTotal = () => {
    return cart.reduce(
      (sum, { count }) => sum + count,
      0
    );
  };




  const getTotalSum = () => {
    return cart.reduce((sum, { attributes, count }) => sum + attributes.price * count, 0);
  };
  

  // const getTotalSum = () => {
  //   return cart.reduce(function(sum, attributes) {

  //   }, 0);
  // };

  const removeFromCart = (productToRemove) => {
    setCart(
      cart.filter((product) => product !== productToRemove)
    );
  };

  return (

    <Routes> 

      <Route path="/" element={<Main cart={cart} setCart={setCart} getCartTotal={getCartTotal} />} />
      <Route path="/merch" element={<Merchandise cart={cart} setCart={setCart} getCartTotal={getCartTotal} />} />
      <Route path="/payment" element={<Payment cart={cart} setCart={setCart} getCartTotal={getCartTotal} checkoutData={checkoutData} setCheckoutData={setCheckoutData} stageCheckout={stageCheckout} setStageCheckout={setStageCheckout}  />} />
      <Route path="/order" element={<Order cart={cart} setCart={setCart} getCartTotal={getCartTotal} checkoutData={checkoutData} setCheckoutData={setCheckoutData} stageCheckout={stageCheckout} setStageCheckout={setStageCheckout} />} />
      <Route path="/endofpayment" element={<EndofPayment cart={cart} setCart={setCart} getCartTotal={getCartTotal} getTotalSum={getTotalSum} checkoutData={checkoutData} setCheckoutData={setCheckoutData}/>}/>
      <Route path='/basket' element={<Basket cart={cart} setCart={setCart} getCartTotal={getCartTotal} getTotalSum={getTotalSum} removeFromCart={removeFromCart} />}/>
      {/* <Route path='/tour2022' element={<Tour />} />
      <Route path='/tour2022test' element={<Tourtest />} /> */}
      <Route path='/password' element={<Password />} />
      <Route path='/privacypolicy' element={<PrivacyPolicy cart={cart} setCart={setCart} getCartTotal={getCartTotal} getTotalSum={getTotalSum} removeFromCart={removeFromCart} />} />
      <Route path='/refund' element={<Refund cart={cart} setCart={setCart} getCartTotal={getCartTotal} getTotalSum={getTotalSum} removeFromCart={removeFromCart} />} />
      <Route path="/product/:id" element={<Product cart={cart} setCart={setCart} getCartTotal={getCartTotal} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />} />
    </Routes>
   
  );
}
export default App;