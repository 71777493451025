import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import '../../../App.scss';
import './Product.scss';
import MerchHeader from './Header/MerchHeader';
import ImageGallery from 'react-image-gallery';
import withSelections from 'react-item-select';
import { useNavigate } from "react-router-dom";
// import {
//   Provider as AlertProvider,
//   useAlert,
//   positions,
//   transitions
// } from 'react-alert';
import axios from "axios";
import Footer from '../../../components/Footer/Footer';



//import MerchandiseHeader from './Merchandise/MerchandiseHeader/MerchandiseHeader';
// import MerchandiseList from '../../Merchandise/MerchandiseList/MerchandiseList';
// import Footer from '../../Footer/Footer';
// import Pagination from './Merchandise/Pagination/Pagination';



export default function Product( {cart, setCart, getCartTotal} ) {
    const {id} = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        fetch(`https://admin.ramilmusic.com/api/merches/${id}?populate=*`)
            .then(res => res.json())
            .then(data => setProduct(data.data))


    }, []);

    const [selectedSize, setSelectedSize] = useState(false);

    // const currentValueSize = (IdProduct) => {
    //     IdProduct = Number(IdProduct);
    //     console.log(IdProduct)
    //     let cartData = JSON.parse(localStorage.getItem('cart'));        
    //     for (let cartItem of cartData) {
    //         if(cartItem.id === IdProduct) {
    //             return cartItem.size;
    //         } 
    //     }

    //     //return product.size;
    // }
    // if(selectedSize == 'null') {
    //     setSelectedSize(currentValueSize(id));
    //     console.log(selectedSize);
    // }

    // const alert = useAlert();
    let navigate = useNavigate();

    const checkAvailable = ({product}) => {
        var stack = Object.keys(product.attributes.ostatok).length;
        for (var key in product.attributes.ostatok) {

            if(product.attributes.ostatok[key]==0) {
                stack--;
            }

        }

        return stack;
    }

    //console.log(checkAvailable(product))

    const SizesSelect = ({ListSizes,IdProduct,setCart,cart}) => {

        var stack = Object.keys(product.attributes.ostatok).length;
        for (var key in product.attributes.ostatok) {

            if(product.attributes.ostatok[key]==0) {
                stack--;
            }

        }

        const handleOnClick = (size,IdProduct) => {
            IdProduct = Number(IdProduct);
            setSelectedSize(size);
            console.log(selectedSize);
            let newCart = [...JSON.parse(localStorage.getItem('cart'))]; 
            let itemInCart = newCart.find(
              (item) => item.id === IdProduct
            );
        
            if(itemInCart) {
                itemInCart.attributes.size = size;
                setCart(newCart);
            } 
            
        }


       
        if(stack!=0) {
            return (
                <>
                    <div className="productSelectSize__label">Размер RU</div>
                    <div className="SizesSelect">
                        {
                            ListSizes.map((size, idx) => (
                                <div key={idx} className={'SizesSelect__size'+((size == selectedSize) ? ' selected' : '')} onClick={event => setSelectedSize(size)}>
                                    <span>{size}</span>
                                </div>
                                )
                            )
                        }
                    </div>
                </>
            )
        } else {
            return '';
        }
    }

    const ButtonBuy = ({product}) => {

        let checkInCart = cart.find(
            (item) => product.id === item.id
        );

        const go_to_basket = () => {
            navigate('/basket', { replace: false });
        }

        const addToCart = (product) => {
            let newCart = [...cart];
            let itemInCart = newCart.find(
                (item) => product.attributes.title === item.attributes.title
            );
            if (itemInCart) {
                itemInCart.count++;
                itemInCart.sum=itemInCart.attributes.price*itemInCart.count;
            } else {
                itemInCart = {
                    ...product,
                    count: 1,
                    sum: product.attributes.price,
                    size: selectedSize ? selectedSize : product.attributes.sizes[0],
                };
                itemInCart.attributes.vendorCode = itemInCart.attributes.vendorCode+'-'+(selectedSize ? selectedSize : product.attributes.sizes[0])

                newCart.push(itemInCart);
            }

            setCart(newCart);

            // alert.show(<div className = "merchandise_alert" style={{ color: 'white', textTransform: 'none', width: '195px' }}>Товар добавлен в корзину</div>,
            // {
            //     timeout: 1000,
            //     type: 'success',
            // }) TUT
        };
        
        var stack = Object.keys(product.attributes.ostatok).length;
        for (var key in product.attributes.statok) {

            if(product.attributes.ostatok[key]==0) {
                stack--;
            }

        }

        if(stack != 0) {

            if (!checkInCart){
                console.log('Заходж');
                return (
                    <div className="productDescription__addToCart" onClick={() => addToCart(product)}>Добавить в корзину</div>
                )
            } else {
                return (
                    <div className="productDescription__addToCart productDescription__addToCart_goToBasket" onClick={() => go_to_basket()}>Перейти в корзину</div>
                )
            }
        } else {
            return (
                    <div className="productDescription__addToCart productDescription__addToCart_notAvailable">Нет в наличии</div>
            )
        }

    }




    function genObjGallery(product) {

        if (product) {

            let GalleryImages = [];

            let i = 0;
            for (let ImgGallery of product.attributes.img.data) {
                GalleryImages[i] = {
                    original: 'https://admin.ramilmusic.com'+ImgGallery.attributes.formats.large.url,
                    thumbnail: 'https://admin.ramilmusic.com'+ImgGallery.attributes.formats.large.url
                }
                i++;
            }

            return GalleryImages;

        } else {
            return [];
        }
    }

    let afaf = genObjGallery(product);
    console.log(genObjGallery(product));

    return (
        <div className="productBody">
            <MerchHeader getCartTotal={getCartTotal} />

            {product && (
                <>
                    <div className="productBodyCart">
                        <div className="productBodyCart__container">

                            <div className="productBodyCart__half productBodyCart__half_title">
                                <h1 className="productBodyCart__title">{product.attributes.title}</h1>
                            </div>

                            <div className="productBodyCart__half productBodyCart__half_gallery">
                                <ImageGallery items={afaf} thumbnailPosition="right" showNav={false} lazyLoad={true} showPlayButton={false} />
                            </div>

                            <div className="productBodyCart__half productBodyCart__half_description">
                                <div className="productDescription">
                                    <h2 className="productBodyCart__price">{product.attributes.price}₽</h2>
                                    <div className="productSelectSize">
                                        <SizesSelect 
                                            ListSizes={product.attributes.sizes} 
                                            IdProduct={id}
                                            setCart={setCart}
                                            cart={cart}
                                        />
                                    </div>
                                    <ButtonBuy product={product}/>
                                    <div className="productDescription__extraInfo">
                                        <div className="productExtraInfo productExtraInfo__deliveryWorldwide">Доставка по всему миру</div>
                                        <div className="productExtraInfo productExtraInfo__refund">14 дней на возврат</div>
                                    </div>
                                    <div className="productDescription__structure">
                                        <div className="productStructure__label">Описание</div>
                                        <div className="productStructure__desc">{product.attributes.structure}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </>
            )}
             <Footer />
        </div>


    );
}