import BadgeDolyame from '../../../assets/images/BadgeDolyame_forBlack.svg';
import ChevronRight from '../../../assets/images/chevron-right.svg';

function WidgetDolyameBtn( {price} ) {  

    function divideAndRound(sum) {
        // Проверяем, является ли sum числом
        if (typeof sum !== 'number') {
          throw new Error('Передайте число в качестве аргумента');
        }
      
        // Делим сумму на 4 и округляем результат
        const result = Math.round(sum / 4);
      
        return result;
    }

    return(
        <div className='widgetDolyame-btn'>
            <div className='widgetDolyame-btn-content'>
                <div className='widgetDolyame-btn__badge'>
                    <div style={{display: 'flex'}}><img src={BadgeDolyame} /></div>
                </div>
            
                <div className='widgetDolyame-btn__title'>
                    <span>4 платежа по {divideAndRound(price)} ₽</span>
                </div>
            </div>
            <div className='widgetDolyame-btn__actions'>
                <div style={{display: 'flex'}}><img src={ChevronRight} /></div>
            </div>
        </div>
    )
}

export default WidgetDolyameBtn;