import React from 'react';
import './ConcertHeader.scss';


export default function ConcertHeader() {
    return (
        <>
            <div className="concert-header__logo">
                <p className="concert-header__logo-text">КОНЦЕРТЫ</p>
            </div>
        </>
    );
}