import React, { useEffect, useState } from 'react';
import Concert from './Concert/Concert';
import ConcertHeader from './ConcertHeader/ConcertHeader';
import ConcertHeaderRow from './ConcertHeaderRow/ConcertHeaderRow';
import './Concerts.scss';
import axios from "axios"

export default function Concerts() {

  const [items, setItems] = useState([]);
  const apiURL = "https://admin.ramilmusic.com/api/concerts?sort=date";

  useEffect(() => {
       getConcerts();
  }, []);

  const getConcerts = () => {
    axios.get(apiURL)
    .then(function (response) {
      setItems(response.data.data);
    })
    .catch(function (error) {
      console.log('error');
    })
  }
  
 // console.log(items);

    return (
        <section className="concerts" id="concerts">
            <ConcertHeader />
            <div className="concerts-info">
                <ConcertHeaderRow />
                <div className="concerts-info__list">
                    {items.map((item, idx)=> 
                      ((Date.now() / 1000) < (new Date(item.attributes.date).getTime() / 1000) + 100000) 
                      ?
                        (
                            <Concert
                                id={item.id}
                                date={item.attributes.date}
                                city={item.attributes.city}
                                club={item.attributes.place}
                                link={item.attributes.link}
                                linkvk={item.attributes.linkvk}
                                typebutton={0}
                                key={idx}
                            />
                        ) 
                      : 
                        ''
                      )
                    }
                </div>
            </div>
        </section>
    );
}