import React from 'react';
import './MobileMusicsHeader.scss';


export default function MusicsHeader() {
    return (
        <div className="mobile-musics__header-logo">
            <p className="mobile-musics__header-logo-text">МУЗЫКА</p>
        </div>
    );
}