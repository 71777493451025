import React, { useEffect, useState } from 'react';
import './MobileConcerts.scss';
import ConcertsHeader from './MobileConcertsHeader/MobileConcertsHeader';
import { MOBILE_CONCERTS } from '../../constants';
import ConcertItem from './MobileConcertItem/MobileConcertItem';
import axios from "axios"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Concerts() {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiURL = "https://admin.ramilmusic.com/api/concerts?sort=date";

  useEffect(() => {
       getConcerts();
  }, []);

  const getConcerts = () => {
    axios.get(apiURL)
    .then(function (response) {
      setItems(response.data.data);
      setIsLoading(false);
    })
    .catch(function (error) {
      console.log('error');
    })
  }

  const SkeletonConcerts = ({count}) => {
    return (
      <>
        {[...Array(count)].map((_, index) => (
          <>
            <div key={index} style={{paddingRight: '50px', paddingLeft: '50px', marginBottom: '30px'}}>
              <Skeleton baseColor="#202020" highlightColor="#444"/>
              <Skeleton baseColor="#202020" highlightColor="#444" style={{marginTop: '10px'}}/>
              <div style={{display: 'flex', flex: '0 0 100%', boxSizing: 'border-box'}} >
                <div style={{flex: '0 0 50%', boxSizing: 'border-box'}}>
                  <Skeleton baseColor="#202020" highlightColor="#444" height='35px' style={{marginTop: '10px'}}/>
                </div>
                <div style={{flex: '0 0 50%', paddingLeft: '16px', boxSizing: 'border-box'}} >
                  <Skeleton baseColor="#202020" highlightColor="#444" height='35px' style={{marginTop: '10px'}}/>
                </div>
              </div>
            </div>

            {(index+1) != count &&
              <div style={{paddingRight: '50px', paddingLeft: '50px', marginBottom: '36px'}}>
                <Skeleton baseColor="#202020" highlightColor="#444" height='1px' style={{marginTop: '10px'}}/>
              </div>
            }
          </>

        ))}
      </>
    );
  }
    return (
        <div id="concerts">
            <ConcertsHeader />
            {isLoading && <SkeletonConcerts count={5} />}
            {items.map((item, idx) => 
              ((Date.now() / 1000) < (new Date(item.attributes.date).getTime() / 1000) + 100000) 
              ?
                (
                  <ConcertItem
                      key={item.id}
                      date={item.attributes.date}
                      name={item.attributes.city}
                      place={item.attributes.place}
                      link={item.attributes.link}
                      linkvk={item.attributes.linkvk}
                      typebutton={0}
                      idx={idx}
                  />
                )
              : 
                ''
              )
            }
        </div>
    );
}