import React from 'react';
import './AboutHeader.scss';


export default function AboutHeader() {
    return (
        <div className="about__header-logo">
            <p className="about__header-logo-text">ABOUT</p>
        </div>
    );
}