import React from 'react';
import  './MobileSummury.scss'


export default function MobileSummury(){
    return(

        <div className="mobile__summury__container">
            <p className='mobile__summury-text'>Итоговая сумма:</p>
            <p className='mobile__summury-text'>3 950₽</p>     
        </div>
    );

}