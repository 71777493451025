import React, {useState, useEffect} from 'react';
import MerchandiseHeader from '../../../components/Merchandise/MerchandiseHeader/MerchandiseHeader';
import './MobileMerchHeader.scss';
import promo from './../../../assets/images/Dolyame promo-widget.jpg';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function MerchHeader() {

    const ImageWithSkeleton = ({ src, alt }) => {
        const [loading, setLoading] = useState(true);
      
        useEffect(() => {
          const image = new Image();
          image.src = src;
      
          image.onload = () => {
            setLoading(false);
          };
      
          return () => {
            // Очистка ресурсов, например, при размонтировании компонента
            image.onload = null;
          };
        }, [src]);
      
        return (
          <div style={{ position: 'relative', width: '100%', height: '100%', padding: '20px 15px 0px', boxSizing: 'border-box'}}>
            {loading && (
                <Skeleton height={(window.innerWidth - 30) / 3.94} baseColor="#202020" highlightColor="#444"/>
            )}
            <img
              src={src}
              alt={alt}
              style={{ display: loading ? 'none' : 'block', width: '100%', height: '100%' }}
            />
          </div>
        );
      };

    return (
        <>
            <div className="mobile-merchandise__header-logo">
                <p className="mobile-merchandise__header-logo-text">МЕРЧ</p>
            </div>
            {/* {<img src={promo} style={{width: '100%', padding: '20px 15px 0px', width: '100%', height: 'auto', boxSizing: 'border-box'}}/> || <Skeleton baseColor="#202020" highlightColor="#444"/>} */}
           

        <ImageWithSkeleton src={promo} alt="Example Image" />
        </>
    );
}  