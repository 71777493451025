import React from 'react';
import './Navigation.scss';
import { useParams, Link } from 'react-router-dom'


export default function Navigation() {
    return (
        <nav className="header-general-info__navigation">
            <a href="/#about_sec" className="header-general-info__navigation-link">ABOUT</a>
            <a href="/#music" className="header-general-info__navigation-link">МУЗЫКА</a>
            <a href="/#concerts" className="header-general-info__navigation-link">КОНЦЕРТЫ</a>
            <Link to="/merch" className="header-general-info__navigation-link">МЕРЧ</Link>
        </nav>
    );
}
