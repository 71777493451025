import React from 'react';
import './Grid.scss';
import karman from '../../../assets/images/karman.png';
import ale from '../../../assets/images/ale.png';
import { useEffect, useState } from 'react';
import Select from 'react-select';


export default function Grid({cart,setCart,removeFromCart}) {
    const customStyles = {
        container: (base, state) => ({
            ...base,
            height: '30px',
            margin: 0,
        }),
        singleValue: (base, state) => ({
            ...base,
            color: 'white',
            fontSize: '1.5rem',
        }),
        menu: (base, state) => ({
            ...base,
            background: '#0F0F0F',
            padding: 0,
            margin: 0,
        }),
        control: (base, state) => ({
          ...base,
          background: "#0F0F0F",
          // Overwrittes the different states of border
          border: 0,
          outline: "none",
          color: 'white',
          width: 'fit-content',
        }),
        option: (base, state) => ({
            ...base,
            padding: "16px 0",
            paddingLeft: '10px',
            background: '#0F0F0F',
            color: 'white',
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            display: 'none',
        }),
        noOptionsMessage: (base, state) => ({
            ...base,
            color: 'white'
        })
    };
    

function sizes(sizes, first = 0) {

    console.log(`Выводим размеры ${sizes}`);


    const sizesList = sizes.map((item, id) => {
        const container = {};

        container.label = item;
        container.value = item;

        return container;
    })

    if(first == 1) {
        console.log(`Условие first == 1 ${sizes}`);
        var firstSize = sizesList[Object.keys(sizesList)[0]];
        return firstSize.label;

    } else {

        return sizesList;

    }
  // console.log(sizesList);
    
}
  const handleChange = (selectedOption, idx) => {
      //console.log(selectedOption.value);
      console.log(idx);  //console.log(cart);
    let newCart = [...cart];
     let itemInCart = newCart.find(
      (item) => idx === item.id
    );
      itemInCart.size = selectedOption.value;
      itemInCart.attributes.vendorCode = itemInCart.attributes.vendorCode.split('-')[0]+'-'+selectedOption.value;

      setCart(newCart);
  };


  const minuscount = (product) => {
    let newCart = [...cart];
    let itemInCart = newCart.find(
        (item) => product.attributes.title === item.attributes.title
      );
      if (itemInCart.count > 1) {
        itemInCart.count--;
        itemInCart.sum = itemInCart.sum - itemInCart.attributes.price;
      }

    setCart(newCart);
  }


  const pluscount = (product) => {
    let newCart = [...cart];
    let itemInCart = newCart.find(
        (item) => product.attributes.title === item.attributes.title
      );
      if (itemInCart.count < 5) {
        itemInCart.count++;
        itemInCart.sum=itemInCart.attributes.price * itemInCart.count;
      }

    setCart(newCart);
  }



    return (
        <div className="table_grid__container">
            <div className="grid__container">
                <div className='grid__name__text'>Товар</div>
                <div className='grid__name__text'></div>
                <div className='grid__name__text__centr'>Цена</div>
                <div className='grid__name__text__centr'>Количество</div>
                <div className='grid__name__text__right'>Итог</div>
            </div>

                {cart.map((product, idx) => (
                    
                    <div className="grid__container" key={idx}>
                        <div className="grid__name__img">
                            <img src={"https://admin.ramilmusic.com" + product.attributes.img.data[0].attributes.formats.large.url}  alt="" className='grig__name__img-image'/>
                        </div>
                        <div className="grid__name__description">
                            <p className="grid__name__description-text">{product.attributes.title}</p>
                            <div className="grid__name__description-size">
                                <p className="grid__name__description-size-text">Размер</p>
                                <Select
                                    styles={customStyles}
                                    options={sizes(product.attributes.sizes)}
                                    aria-label="Размер"
                                    placeholder={product.size}
                                    onChange={(selectedOption) => handleChange(selectedOption, product.id)}
                                />
                            </div>
                            <a className='grid__name__description-link' onClick={() => removeFromCart(product)}>УДАЛИТЬ ИЗ КОРЗИНЫ</a>
                        </div>
                        <div className='grid__name__count'>{product.attributes.price} ₽</div>
                        <div className='grid__name__count'>    
                                <button className='grid__name__count__minus' onClick={() => minuscount(product)}><span className='grid__name__count__minus__minus'>-</span></button>
                                <input className ='grid__name__quanity-input' value = {product.count} />
                                <button className='grid__name__count__plus'  onClick={() => pluscount(product)}><span>+</span></button>
                        </div>
                        <div className='grid__name__count'>{product.sum} ₽</div>
                    </div>


                ))}




                
        </div>
    );

}