import React from 'react';
import MobileGrid from './MobileGrid/MobileGrid';
import MobileResult from './MobileResult/MobileResult';
import HeaderWithStock from '../MobileHeaderWithStock/MobileHeaderWithStock';
import Footer from '../../mobileComponents/MobileFooter/MobileFooter';
import { useLocation, useNavigate } from 'react-router-dom'
import { Select } from '@chakra-ui/react'
import LogoDolyameSmall from '../../assets/images/Dolyame small logo.svg';
import ChevronRightBlack from '../../assets/images/chevron-right-black.svg';
import dolyameAdvantageIconOne from '../../assets/images/dolyame-drawer_advantage-1.svg';
import dolyameAdvantageIconTwo from '../../assets/images/dolyame-drawer_advantage-2.svg';
import LogoDolyame from '../../assets/images/LogoDolyame.svg';

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
  } from '@chakra-ui/react'

export default function MobileBasketMain( {cart, setCart, getCartTotal, getTotalSum, removeFromCart} ){
    const { state } = useLocation()
    let navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()

    function divideAndRound(sum) {
        // Проверяем, является ли sum числом
        if (typeof sum !== 'number') {
          throw new Error('Передайте число в качестве аргумента');
        }
      
        // Делим сумму на 4 и округляем результат
        const result = Math.round(sum / 4);
      
        return result;
    }

    function datesDolyame() {
        const months = {
             0: 'янв',
             1: 'фев',
             2: 'марта',
             3: 'апр',
             4: 'мая',
             5: 'июня',
             6: 'июля',
             7: 'авг',
             8: 'сен',
             9: 'окт',
             10: 'ноя',
             11: 'дек',
         }
       
         const dateNow = Date.now() / 1000;
         const dates = [];
         const formatedDates = [];
 
         for(let count = 0; count < 3; count++) {
             if(count == 0) {
                 dates[count] = dateNow + 1209600;
             } else {
                 dates[count] = dates[count - 1] + 1209600;
             }
         }
         
         dates.map((date, id) => {
              formatedDates[id] = new Date(date * 1000).getDate() + ' '+months[new Date(date * 1000).getMonth()]
         });
     
         return formatedDates;
     }

    return(
        <div className="container">
            <HeaderWithStock getCartTotal={getCartTotal}/>
            <h2 className="container__title">КОРЗИНА</h2>
            {!state?.dolyame && 
                <div className='dolyamePromoWidget-basket' onClick={onOpen}>
                    <div className='dolyamePromoWidget-basket-content'>
                        <div className='dolyamePromoWidget-basket__logo'>
                            <div>
                                <img src={LogoDolyameSmall} />
                            </div>
                        </div>
                        <div className='dolyamePromoWidget-basket__desc'>
                            <div>
                                Оплатите Долями сегодня {divideAndRound(getTotalSum())}  ₽
                            </div>
                            <div>
                                Остальное потом, с платежом раз в 2 недели
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={ChevronRightBlack} />
                        </div>
                    </div>
                </div>
            }
            <MobileGrid cart={cart} setCart={setCart} removeFromCart={removeFromCart}/>
            {state?.dolyame && 
                <Select variant='Долями' placeholder='Долями' className='paymentMethod-select'>
                    <option value='option1'>Картой</option>
                </Select>
            }
            <MobileResult getTotalSum={getTotalSum()}/>

            <Footer />
            <Drawer placement='bottom' onClose={onClose} isOpen={isOpen}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            {/* <DrawerHeader borderBottomWidth='1px'>Basic Drawer</DrawerHeader> */}
                            <DrawerBody>
                                <div className='drawer-content'>
                                    <div className='drawer-content__title'>Оплатите 25%<br></br>от стоимости покупки</div>
                                    <div className='drawer-content__desc'>Оставшиеся 3 части спишутся<br></br>автоматически с шагом в две недели</div>
                                    <div className='drawer-content__advantages' style={{marginBottom: '16px'}}>
                                        <div className="dolyame-drawer_advantage" style={{marginBottom: '12px'}}>
                                            <div className='dolyame-drawer_advantage__icon'>
                                            <div style={{display: 'flex'}}><img src={dolyameAdvantageIconOne} /></div>
                                            </div>

                                            <div className='dolyame-drawer_advantage__text'>
                                                <span>Без процентов и комиссий</span>
                                            </div>
                                        </div>

                                        <div className="dolyame-drawer_advantage">
                                            <div className='dolyame-drawer_advantage__icon'>
                                                <div style={{display: 'flex'}}><img src={dolyameAdvantageIconTwo} /></div>
                                            </div>

                                            <div className='dolyame-drawer_advantage__text'>
                                                <span>Как обычная оплата картой</span>
                                            </div>
                                        </div>

                                    </div>
                                    
                                        
                                    <div className='drawer-content__paymentDetails'>
                                        {/* {[...Array(4)].map((_, index) => ( */}
                                            <div className={`paymentDetails-item paymentDetails-item_active`}>
                                                <div className='paymentDetails-item__date'>
                                                    Сегодня
                                                </div>
                                                <div className='paymentDetails-item__sum'>
                                                    {divideAndRound(getTotalSum())} ₽
                                                </div>
                                                <div className='paymentDetails-item__line'>
                                                    <div className='line'></div>
                                                </div>
                                            </div>
                                        {/* ))} */}
                                        
                                        {datesDolyame().map((date, index) => ( 
                                            <div className={`paymentDetails-item`}>
                                                <div className='paymentDetails-item__date'>
                                                    {date}
                                                </div>
                                                <div className='paymentDetails-item__sum'>
                                                    {divideAndRound(getTotalSum())} ₽
                                                </div>
                                                <div className='paymentDetails-item__line'>
                                                    <div className='line'></div>
                                                </div>
                                            </div>
                                        ))}
                                    
                                    </div>
                                    <div className='drawer-content__actions' onClick={() => navigate('/payment')}>
                                        <div className='btnPayDolyame' style={{marginBottom: '20px'}}>
                                            <div style={{marginRight: '8px'}}>Оплатить</div>
                                            <img src={LogoDolyame} />
                                        </div>
                                        <div className='detailsDolyame' style={{textAlign: 'center'}}>
                                            <span style={{fontSize: '12px', color: '#00000066'}}>Подробности на </span><a href='https://dolyame.ru' style={{fontSize: '12px', color: '#1771E6', textDecoration: 'none'}}>dolyame.ru</a>
                                        </div>
                                    </div>
                                </div>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
        </div>
    );

}