import React from 'react';
import './MerchandiseHeader.scss';


export default function MerchandiseHeader() {
    return (
        <div className="merchandise__header-logo">
            <p className="merchandise__header-logo-text">МЕРЧ</p>
        </div>
        
    );
}