import React, { useEffect, useState } from 'react';
import './MobileMusicsList.scss';
import { musics } from '../../../constants';
import MusicsListItem from './MobileMusicsListItem/MobileMusicsListItem';
import axios from "axios"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function MusicsList() {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiURL = "https://admin.ramilmusic.com/api/releases?populate=*&sort=rank:asc";

  useEffect(() => {
       getConcerts();
  }, []);

  const getConcerts = () => {
    axios.get(apiURL)
    .then(function (response) {
      setItems(response.data.data);
      setIsLoading(false);
    })
    .catch(function (error) {
      console.log('error');
    })
  }

  const SkeletonMerch = ({count}) => {
    return (
      <div className='skeleton_merchandise__list' style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', padding: '20px 15px 30px', width: '100%'}}>
        {[...Array(count)].map((_, index) => (
          <div className='skeleton_merchandise__item' style={{width: '45%', display: 'flex', flexDirection: 'column', marginBottom: '25px'}}>
            <Skeleton height='165px' baseColor="#202020" highlightColor="#444" style={{marginBottom: '12px'}} />
            <Skeleton baseColor="#202020" highlightColor="#444" style={{marginBottom: '16px'}}/>
            <Skeleton baseColor="#202020" highlightColor="#444" style={{marginBottom: '12px'}}/>
          </div>
        ))}
      </div>
    );
  }
    return (
        <div className="mobile-musics__list">
      
          {isLoading && <SkeletonMerch count={6}/>} 
           {/* <SkeletonMerch count={6}/>  */}

            {items.map(item => (
                <MusicsListItem
                    item={item}
                />
            ))}
        </div>
    );
}