import React, { useEffect, useState } from 'react';
import axios from "axios"
import './MobileMerchList.scss';
import { merchandise } from '../../../constants';
import MerchListItem from './MobileMerchListItem/MobileMerchListItem';
import { useNavigate } from "react-router-dom";
// import {
//   Provider as AlertProvider,
//   useAlert,
//   positions,
//   transitions
// } from 'react-alert';
import dolyamelogo from './../../../assets/images/dolyamelogo.svg';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useToast } from '@chakra-ui/react'

export default function MerchList({ setCart, cart }) {

  // const alert = useAlert();
  let navigate = useNavigate();
  const toast = useToast()

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiURL = "https://admin.ramilmusic.com/api/merches?populate=*";

  useEffect(() => {
       getMerch();
  }, []);


  const Button_Merch = ({product}) => {

    let checkInCart = cart.find(
        (item) => product.id === item.id
    );

    const go_to_basket = () => {
      navigate('/basket', { replace: false });
    }



    const addToCart = (product) => {
      let newCart = [...cart];
      let itemInCart = newCart.find(
        (item) => product.attributes.title === item.attributes.title
      );
      if (itemInCart) {
          itemInCart.count++;
          itemInCart.sum=itemInCart.attributes.price*itemInCart.count;
      } else {
        itemInCart = {
          ...product,
          count: 1,
          sum: product.attributes.price,
          size: product.attributes.sizes[0],
        };
        itemInCart.attributes.vendorCode = itemInCart.attributes.vendorCode+'-'+itemInCart.attributes.sizes[0];

        newCart.push(itemInCart);
      }
   
      setCart(newCart);
      
 
      // alert.show(<div className = "merchandise_alert" style={{ color: 'white', textTransform: 'none', width: '195px' }}>Товар добавлен в корзину</div>,
      // {
      //   timeout: 1000, // custom timeout just for this one alert
      //   type: 'success',
      // })
    };
    



    var stack = Object.keys(product.attributes.ostatok).length;
    for (var key in product.attributes.ostatok) {

        if(product.attributes.ostatok[key]==0) {
            stack--;
        }

    }
//  console.log(stack);
    
  if(stack != 0) {
      if (!checkInCart){
              console.log('Заходж');

          return (
                  <a className="mobile-merchandise__list-item-buy" onClick={() => { addToCart(product); toast({ description: "Товар добавлен в корзину", status: 'success', duration: 4000, isClosable: false, position: 'top' })}}>Купить</a>
          )
      } else{

          return (
                  <a className="mobile-merchandise__list-item-buy mobile-merchandise__list-item-buy_goToBasket" onClick={() => go_to_basket()}>В корзине</a>
          )


      }
  } else {
    return (
      <span className="mobile-merchandise__list-item_notAvailable">Нет в наличии</span>
      )
  }

  }

  const getMerch = () => {
    axios.get(apiURL)
    .then(function (response) {
      setItems(response.data.data);
      setIsLoading(false);
    })
    .catch(function (error) {
      console.log('error get merch');
    })
  }

  const addToCart = (product) => {

    let newCart = [...cart];

    let itemInCart = newCart.find(
      (item) => product.attributes.title === item.attributes.title
    );
    if (itemInCart) {
        itemInCart.count++;
        itemInCart.sum=itemInCart.attributes.price*itemInCart.count;
    } else {
      itemInCart = {
        ...product,
        count: 1,
        sum: product.attributes.price,
        size: product.attributes.sizes[0],
      };
      newCart.push(itemInCart);
    }
    console.log(itemInCart);
    setCart(newCart);
    
  };

  const SkeletonMerch = ({count}) => {
    return (
      <div className='skeleton_merchandise__list' style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', padding: '20px 15px 30px'}}>
        {[...Array(count)].map((_, index) => (
          <div className='skeleton_merchandise__item' style={{width: '45%', display: 'flex', flexDirection: 'column', marginBottom: '30px'}}>
            <Skeleton height='165px' baseColor="#202020" highlightColor="#444" style={{marginBottom: '12px'}} />
            <Skeleton baseColor="#202020" highlightColor="#444" style={{marginBottom: '12px'}}/>
            <Skeleton baseColor="#202020" highlightColor="#444" style={{marginBottom: '12px'}}/>
            <Skeleton baseColor="#202020" highlightColor="#444" style={{marginBottom: '15px'}}/>
            <Skeleton height='41px' baseColor="#202020" highlightColor="#444" />
          </div>
        ))}
      </div>
    );
  }

    return (
        <>
        {/* {<SkeletonMerch count={6} />} */}
          {isLoading && <SkeletonMerch count={6} />} 

          <div className="mobile-merchandise__list">
              {items.map((product, idx) => (
                <div className="mobile-merchandise__list-item" key={idx}>
                    <img src={"https://admin.ramilmusic.com" + product.attributes.img.data[0].attributes.formats.large.url} alt="Merchandise Item" className="mobile-merchandise__list-item-img"  onClick={event => navigate('/product/'+product.id, { replace: false })}/>
                    <h2 className="mobile-merchandise__list-item-title">{product.attributes.title}</h2>
                    <div className="mobile-merchandise__list-item__price">
                        <p className="current-price">{product.attributes.price}₽</p>
                        <del className="previous-price"></del>
                    </div>
                    <div className='dolyameWidgetCatalog' style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                      <div className='dolyameWidgetCatalog__logo'>
                        <img src={dolyamelogo} style={{marginRight: '6px', position: 'relative', top: '1.5px'}}/>
                      </div>
                      <div className='dolyameWidgetCatalog__text' style={{fontSize: '12px', color: 'white', fontFamily: 'Arial'}}>
                        Долями по {(product.attributes.price / 4)} ₽
                      </div>
                    </div>
                    <Button_Merch product = {product} />

                </div>
              ))}
          </div>
        </>
    );
}